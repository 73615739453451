import React from 'react'

const Footer = () => {
    return (
        <div className=''>
            <div className='text-center p-4 text-white'>© 2022 Take Cover. All rights reserved.</div>
        </div>
    )
}

export default Footer