import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import "./picture.css"

function PictureGallery(props) {
    var items = [
        {
            name: "Chad",
            src: "chad (2).jpg"
        },
        {
            name: "Chad",
            src: "chad (3).jpg"
        },
        {
            name: "Chad",
            src: "chad (4).jpg"
        },
        {
            name: "Jeff",
            src: 'jeff.jpg',
        },
        {
            name: "Chad",
            src: "chad (5).jpg"
        },
        {
            name: "Chad",
            src: "chad (8).jpg"
        },
        {
            name: "Chad",
            src: "chad (9).jpg"
        },
        {
            name: "Chad",
            src: "chad (10).jpg"
        },
        {
            name: "Jay",
            src: "jay.jpg"
        },
        {
            name: "Jay",
            src: "takecover_drum.jpg"
        },
        {
            name: "Group",
            src: "takecover_group.jpg"
        }
    ]

    return (
        <>
            <div className='container-fluid m-0'>
                <div className='row'>
                    <h1 className='text-secondary text-center fw-bold w-100'>See the Band in Action</h1>

                    <div className=' pt-3'>
                        <div className="m-auto" >
                            <div>
                                <div className=' p-1'>
                                    <Carousel className='' >
                                        {
                                            items.map((item, i) => <Item key={i} item={item} />)
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-section">
                <div class="video-container">
                    <iframe
                        class="video-frame"
                        src="https://www.youtube.com/embed/Jg4ZwElXiNE?modestbranding=1;controls=0;showinfo=0;rel=0;fs=1"
                        title="🎸 BON JOVI 🎸 YOU GIVE LOVE A BAD NAME 💘 COVERED BY TAKE COVER 🏜😎🎤🎸👍"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>
                <div class="video-container">
                    <iframe
                        class="video-frame"
                        src="https://www.youtube.com/embed/2ScZkF8BBgM?modestbranding=1;controls=0;showinfo=0;rel=0;fs=1"
                        title="Take Cover! Va Bene"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </>
    )
}

function Item(props) {
    return (
        <>
            <Paper  >
                <div className='text-center text-white bg-black d-flex w-100 justify-content-center'>
                    <div className='bg-white p-3 rounded-3' >
                        <img alt={props.item.name} style={{ height: '500px' }} className="rounded-3" src={`/images/${props.item.src}`}></img>
                    </div>
                    <br></br>
                </div>
            </Paper>
        </>
    )
}

export default PictureGallery